import React, {useState, useEffect} from 'react';

const Chart = (props) => {

    const [result, setResult] = useState(0);
    var completed = 0;
    var tickInterval = null;
    const tick = () => {  
        completed++;
        setResult(completed);
        if(completed >= props.pct){
            clearInterval(tickInterval);
        } 
    } 

    useEffect(() => { 
        if(props.pct){ 
            setTimeout(() => { 
                tickInterval = setInterval(
                    () => tick(),
                    20
                ); 
            }, 500);
        }

        return () => {
            clearInterval(tickInterval);
        }
        
        
    }, [props.pct]);
    
    return (
        <figure className="chart-wrap"> 
            <div  className={`ch ${'p' + result} ${ result < 33 ? "bad" : ""} ${ (result < 66 && result >= 33) ? "moderate" : ""} ${ result >= 66 ? "good" : ""} `} > 
                <span>{result}%</span> 
                <div className="slice">
                    <div className="bar"></div>
                    <div className="fill"></div>
                </div>
            </div>  
        </figure> 
    )
};
export default Chart;


