import React, { useContext } from 'react';
import { NavLink, Redirect } from 'react-router-dom';

import JSONHelper from '../helpers/JSON';
import QuizHelper from '../helpers/Quiz';

import AppContext from '../AppContext';
import Layout from '../layout/Default';
import Subtitle from './components/Subtitle';
import Head from './components/Head';
import Chart from './components/Chart';

const Result = (props) => {
    const appState = useContext(AppContext);


    const sectionIdent = Number(props.match.params.section);
    const activeSectionJSON = JSONHelper.getSection(appState.data, sectionIdent);

    const currentResult = QuizHelper.getSectionScore(appState, sectionIdent);

    //get next unanseered section
    const nextSection = QuizHelper.getNextSection(appState, sectionIdent);
    if (nextSection === false) {
        return <Redirect to='/results' />;
    }

    const nextSectionJSON = JSONHelper.getSection(appState.data, nextSection);

    return (
        <Layout>

            <Head closeUrl="/"  />

            <main className={`result has-sticky ${(sectionIdent & 1) ? "odd" : "even"}`}>

                <Subtitle subTitle={JSONHelper.getSingleTextValue(activeSectionJSON, 'title')} sectionIdent={sectionIdent} showProgress={true}></Subtitle>

                <article>
                    <h3>You’ve completed all questions in {JSONHelper.getSingleTextValue(activeSectionJSON, 'title')}. Your score is:</h3>
                </article>

                <Chart pct={currentResult} /> 

                <div className="bottom">
                    <NavLink to={"/directions/" + nextSection} className="red btn" aria-label="Next">{JSONHelper.getSingleTextValue(nextSectionJSON, 'title')}</NavLink>
                </div>
            </main>


        </Layout>
    )
}
export default Result;
