import React, { useState, useRef, useEffect, useContext } from 'react';
import AppContext from '../../AppContext';
import JSONHelper from '../../helpers/JSON';

const Share = (props) => {

    const appState = useContext(AppContext);

    const [showShare, setShowShare] = useState(false);
    const mailtoRef = useRef(null)
    const pageUrl = encodeURIComponent(document.URL);

    const showHideShare = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            setShowShare(true)
        }
    }

    useEffect(() => { 
        /*
        const secitonsJSON = JSONHelper.getJSON(appState.data, 'sections');
        console.log(secitonsJSON);
        for (var s in secitonsJSON) {
            const sectionIdent = Number(s) + 1;
            const json = secitonsJSON[s]._source;
            const questionsJSON = json.questions;
            const sectionTitle = JSONHelper.getSingleTextValue(json, 'title');
            const questionsTypeText = JSONHelper.getValue(json, 'questionTypeText', 'question'); // question, stop  

            console.log(sectionTitle);
 
            for (var q in questionsJSON) {
                const questionIdent = Number(q) + 1;
                const qectionJSON = appState.data['question_' + sectionIdent + '_' + questionIdent]; 
                const questionTitle = questionsTypeText + ' ' + questionIdent;
                console.log(questionTitle);
            }
        }*/

    }, [props]);

    //  onMouseOut={() => setShowShare(false)}

    const openSocialWindow = (url) => {
        const wWidth = Math.min(600, window.screen.width);

        const left = (window.screen.width - wWidth) / 2;
        const top = (window.screen.height - 500) / 2;

        var params = "menubar=no,toolbar=no,status=no,width=" + wWidth + ",height=500,top=" + top + ",left=" + left;
        window.open(url, "shareWindow", params);
    };


    const shareByMail = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            var mailto = 'mailto:?subject=';
            if (props.homepage) {
                mailto += encodeURIComponent('Take the Royal Air Force Museum’s Air Cadet quiz');
            } else {
                mailto += encodeURIComponent('I just scored ' + props.completed + '% in the RAF Museum Air Cadet Quiz. Could you do better? Try it for yourself');
            } 

            //if (includeResults) {
            mailto += '&body=' + pageUrl;
            //}

            mailtoRef.current.href = mailto;
        }
    }

    const shareOnFb = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            var tweet = '';;
            if (props.homepage) {
                tweet = 'Take the Royal Air Force Museum’s Air Cadet quiz #RAFMtours';
            } else {
                tweet = 'I just scored ' + props.completed + '% in the Air Cadet Quiz. Could you do better? Try it for yourself.';
            }

            const url = "https://www.facebook.com/sharer.php?u=" + pageUrl;
            //const url = "https://www.facebook.com/dialog/feed?app_id=770972223331451&display=popup&caption=#RAFMtours&link=http://aircadetquiz.code8.cz&description=" + encodeURIComponent(tweet) + "";
            openSocialWindow(url);
        }
    }

    const shareOnTw = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            var tweet = ''; 

            if (props.homepage) {
                tweet = 'Take the @RAFMUSEUM’s Air Cadet quiz';
            } else {
                tweet = 'I just scored ' + props.completed + '% in the Air Cadet Quiz. Could you do better? Try it for yourself with @RAFMUSEUM’s';
            }
            
            const url = "https://twitter.com/intent/tweet?url=" + pageUrl + "&hashtags=RAFMtours&text=" + encodeURIComponent(tweet);
            openSocialWindow(url);
        }
    }
 
return (
    <div className={`share ${showShare ? "clicked" : ""} ${props.text ? "with-text" : ""}`}>
        <span tabIndex="0" onClick={(event) => showHideShare(event)} onKeyDown={(event) => showHideShare(event)} aria-label="Share"><span>{props.text ? 'Share with your friends' : ''}</span></span>
        <ul>
            <li className="fb" tabIndex={(showShare ? '0' : '-1')} onClick={(event) => shareOnFb(event)} onKeyDown={(event) => shareOnFb(event)}  aria-label="Share on Facebook"></li>
            <li className="tw" tabIndex={(showShare ? '0' : '-1')} onClick={(event) => shareOnTw(event)} onKeyDown={(event) => shareOnTw(event)}  aria-label="Share on Twitter"></li>

            <li className="em" aria-label="Share by email"><a ref={mailtoRef} tabIndex={(showShare ? '0' : '-1')} onClick={(event) => shareByMail(event)} onKeyDown={(event) => shareByMail(event)}  target="_blank" rel="noopener noreferrer"></a></li> 


        </ul>
    </div>
)
};
export default Share;
