import React from 'react'; 

import Logo from './Logo';

const Header = () => {
  return(
    <header>
        <Logo />        
    </header>
  )
}
export default Header;
