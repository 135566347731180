import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import AppContext from '../../AppContext';

import JSONHelper from '../../helpers/JSON';
import QuestionOption from './QuestionOption';
import Image from './Image';

const Question = (props) => {
    const appState = useContext(AppContext);

    const [visible, setVisible] = useState(false);
    const [anweredCount, setAnweredCount] = useState(0);
    const [questionState, setQuestionState] = useState({});

    const questionIdent = props.questionIdent;
    const sectionIdent = props.sectionIdent;

    useEffect(() => {
        if (appState.answers[sectionIdent] && appState.answers[sectionIdent][questionIdent]) {
            // was clicked 
            setAnweredCount(Object.keys(appState.answers[sectionIdent][questionIdent]).length);
        }
        if (appState.answers[sectionIdent] && appState.answers[sectionIdent][questionIdent]) {
            setQuestionState(appState.answers[sectionIdent][questionIdent]);
        }

    }, []);


    const json = props.json;
    if (!questionIdent || !json) {
        return '';
    }

    const questionType = JSONHelper.getValue(json, 'questionType', 'text');
    const questionTitle = props.questionsTypeText + ' ' + questionIdent;
    const minimumAnswers = JSONHelper.getValue(json, 'minimumAnswers', '1')

    if (!appState.answers[sectionIdent]) {
        appState.answers[sectionIdent] = {};
    }
    if (!appState.answers[sectionIdent][questionIdent]) {
        appState.answers[sectionIdent][questionIdent] = {};
    }


    const toggleQuestion = (event) => {
        if (props.accordion) {
            if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
                setVisible(!visible);
            }
        }
    };

    const answerClicked = (no, isCorrect, isSelected) => {

        if (!props.accordion) {

            if (isSelected) {


                var selectedAnswersCount = 1 + Object.keys(appState.answers[sectionIdent][questionIdent]).length;

                if (selectedAnswersCount) {
                    if (selectedAnswersCount > minimumAnswers) {
                        // remove prev one
                        var lastIdent = Object.keys(appState.answers[sectionIdent][questionIdent]).pop();
                        delete (appState.answers[sectionIdent][questionIdent][lastIdent]);
                    }
                }

                appState.answers[sectionIdent][questionIdent][no] = {
                    isCorrect: isCorrect,
                    minimumAnswers: minimumAnswers
                };

            } else {
                delete (appState.answers[sectionIdent][questionIdent][no]);
            }

            setAnweredCount(Object.keys(appState.answers[sectionIdent][questionIdent]).length);
            setQuestionState(Object.keys(appState.answers[sectionIdent][questionIdent]));

            // store
            localStorage.setItem('app_state_answers', JSON.stringify(appState.answers));
        }
    };

    const renderSwitch = (type, state) => {
        switch (type) {
            case 'text':
                const questionIntoText = JSONHelper.getValue(json, 'typeText', []);
                const optionsText = JSONHelper.getValue(questionIntoText, 'options', []);

                return (
                    <ol className="options-text">
                        {optionsText.map((option, i) => {  
                            return (<QuestionOption accordion={props.accordion} isExiting={props.isExiting} callback={answerClicked} key={i} no={i + 1} state={state} sectionIdent={sectionIdent} questionIdent={questionIdent} accordion={props.accordion} json={option} type={type} />)
                        })}
                    </ol>
                );
            case 'images':

                const questionIntoImages = JSONHelper.getValue(json, 'typeImages', []);
                const optionsImages = JSONHelper.getValue(questionIntoImages, 'options', []);
                return (
                    <ol className="options-images">
                        {optionsImages.map((option, i) => {
                            return (<QuestionOption accordion={props.accordion} isExiting={props.isExiting} callback={answerClicked} key={i} no={i + 1} state={state} sectionIdent={sectionIdent} questionIdent={questionIdent} accordion={props.accordion} json={option} type={type} />)
                        })}
                    </ol>
                );
            case 'mix':
                const questionIntoMix = JSONHelper.getValue(json, 'typeMix', []);
                const optionsMix = JSONHelper.getValue(questionIntoMix, 'options', []);
                const multimedia = JSONHelper.getValue(questionIntoMix, 'multimedia', false);
                var imgData = false;
                if (multimedia && multimedia[0]) {
                    imgData = multimedia[0];
                }

                return (
                    <div className="mix">
                        <figure>
                            <Image json={imgData} size="medium" />
                        </figure>
                        <ol className="options-text">
                            {optionsMix.map((option, i) => {
                                return (<QuestionOption accordion={props.accordion} isExiting={props.isExiting} callback={answerClicked} key={i} no={i + 1} state={state} sectionIdent={sectionIdent} questionIdent={questionIdent} accordion={props.accordion} json={option} type="text" />)
                            })}
                        </ol>
                    </div>
                );
            default:
                return '';
        }
    };


    return (
        <div className={`question ${visible ? "visible" : ""}`}>

            <div className="res-padding">
                <h3 tabIndex={(props.accordion && props.isVisible && props.visibleSection ? '0' : '-1')} onClick={(event) => toggleQuestion(event)} onKeyDown={(event) => toggleQuestion(event)}>
                    {questionTitle}
                </h3>
            </div>
            <div className="question-body">
                <p>{JSONHelper.getSingleTextValue(json, 'question')}</p>

                <div className="options-wrap">
                    {renderSwitch(questionType, questionState)}
                </div>

            </div>

            {!props.accordion &&
                <div className="bottom">
                    <NavLink tabIndex={(props.isExiting ? '-1' : '0')} to={props.nextPageUrl} className={`red btn ${(anweredCount < minimumAnswers) ? "disabled" : ""}`} aria-label="Continue">{props.nextPageButton}</NavLink>
                </div>
            }


        </div>


    )
};
export default Question;
