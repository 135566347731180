import React from 'react';
import JSONHelper from '../../helpers/JSON';
import Image from './Image';

const Sponsor = (props) => {
    const multimedia = JSONHelper.getValue(props.sponsor, 'multimedia', false);
    var imgData = false;
    if(multimedia && multimedia[0]){
        imgData = multimedia[0];
    }
    return (
        <li> 
            <Image json={imgData} />
            <a rel="noopener noreferrer" href={ JSONHelper.getValue(props.sponsor, 'link', '') } target="_blank"><span>{ JSONHelper.getSingleTextValue(props.sponsor, 'attributionText') }</span></a>
        </li>
    )
};
export default Sponsor;
