import React from 'react';

import Header from './components/Header';

const Layout = (props) => {
  return (
    <div className="page">
      <Header /> 
        <div className="container" id="container">
        { props.children }
        </div> 
    </div>
  )
}
export default Layout;
