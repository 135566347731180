
import React from 'react';
import JSONHelper from '../../helpers/JSON';

const Image = (props) => {
    let url, alt;
    if(props.json){ 

        url = JSONHelper.getImageUrl(props.json, props.size);
        
        alt = JSONHelper.getSingleTextValue(props.json.source, 'title');
    } 
     
    return props.json ? (
        <img src={url} alt={alt} />
    ) : '';
};
export default Image;

