import React, {useState, useContext} from 'react';
import {NavLink, Redirect} from 'react-router-dom';

import JSONHelper from '../helpers/JSON';

import Layout from '../layout/WithHeader';
import Share from './components/Share';
import Sponsors from './components/Sponsors';
import AppContext from '../AppContext'; 
import Image from './components/Image';


const Home = (props) => { 
        
    const appState = useContext(AppContext)
    const homepageJSON = JSONHelper.getSingleJSON(appState.data, 'homepage');
    const sponsors = JSONHelper.getValue(homepageJSON, 'sponsors', false);
    const multimedia = JSONHelper.getValue(homepageJSON, 'multimedia', false);

    var imgData = false; 
    if (multimedia && multimedia[0]) {
        imgData = multimedia[0];
    }  
 
    const [showMore, setShowMore] = useState(false);
     
    const lastPageVisited = localStorage.getItem("last_page");
    if(lastPageVisited && appState.redirectedBack !== true){ 
        appState.redirectedBack = true; 
        return <Redirect to={lastPageVisited} />;
    }

    const showHideMore = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            setShowMore(!showMore)
        }
    }
    
    return (
        <Layout>
            <main className="homepage">
                <article>
                    <h1>{ JSONHelper.getSingleTextValue(homepageJSON, 'title') }</h1>
                    <h2>{ JSONHelper.getSingleTextValue(homepageJSON, 'headline') }</h2>
                    <span className="read-more" tabIndex="0" onClick={(event) => showHideMore(event)} onKeyDown={(event) => showHideMore(event)} aria-label="Read more"><span>{ showMore ? 'Read less' : 'Read more'}</span></span>
                    <div className={`more ${ showMore ? "visible" : ""}`}>

                        <div dangerouslySetInnerHTML={{__html: JSONHelper.getSingleTextValue(homepageJSON, 'summary')}} /> 
                        
                        <Sponsors sponsors={sponsors} />

                    </div> 
                    <Share homepage={true} /> 
                </article>

                <div className="hpi">
                    <Image json={imgData} size="medium" />
                </div>

                <div className="bottom">
                    <NavLink to="/sections" className="red btn">Start</NavLink> 
                </div>
            </main>
        </Layout>
    )
}
export default Home;
