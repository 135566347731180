import React from 'react';
import Image from './Image';
import JSONHelper from '../../helpers/JSON';

const Map = (props) => {

    const json = props.json;
    const description = JSONHelper.getSingleTextValue(json, 'description');
    const multimedia = JSONHelper.getValue(json, 'multimedia', false);
    const markerPosition = JSONHelper.getValue(json, 'markerPosition', {left: 50, top: 50});
    const key = (props.no < 10 ? '0' + props.no : props.no);

    var imgData = false;
    var zoomImgUrl = '';
    if (multimedia && multimedia[0]) {
        imgData = multimedia[0];
    }
    if(imgData){
        zoomImgUrl = JSONHelper.getImageUrl(imgData, "medium");
    } 

    return (
        <li>
            <em>{key}</em>
            <figure className="map-container" tabIndex="0" onClick={() => props.showDetail(zoomImgUrl, markerPosition)}>
                <Image json={imgData} size="medium" />
                <span className="marker" style={ {left: markerPosition.left + '%', top: markerPosition.top + '%'} }></span> 
                <i className="zoom"></i>
            </figure>

            {(description && description.length > 0) &&
                <p>{description}</p>
            }

        </li>
    )
};
export default Map;
