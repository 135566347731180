import React from 'react'; 

const Logo = (props) => {
  return(
    <a
        className="logo"
        href="https://www.rafmuseum.org.uk/"
        target="_blank"
        rel="noopener noreferrer"
    >
        <span>Royal AirForce musem</span>
    </a>
  )
}
export default Logo;
