import React, { useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import JSONHelper from '../helpers/JSON';
import AppContext from '../AppContext';

import Subtitle from './components/Subtitle'; 
import Layout from '../layout/Default';
import Image from './components/Image';

import Head from './components/Head';
import Map from './components/Map';

const Directions = (props) => { 

    const appState = useContext(AppContext)

    const [showDetail, setShowDetail] = useState(false);
    const [detailImage, setDetailImage] = useState(false);
    const [markerPosition, setMarkerPosition] = useState({});

    // console.log(props);
    
        
    appState.lastSectionProgress = 0; // reset
    
    const sectionIdent = Number(props.match.params.section); 

    if(!sectionIdent){ 
        return '';
    }

    const activeSectionJSON = JSONHelper.getSection(appState.data, sectionIdent);
    const directionsJSON = JSONHelper.getValue(activeSectionJSON, 'directions', []);
    
    const multimedia = JSONHelper.getValue(directionsJSON, 'multimedia', false);
    

    var imgData = false;
    if (multimedia && multimedia[0]) {
        imgData = multimedia[0];
    }


    const showDetailImage = (img, markerPosition) => {
        setMarkerPosition(markerPosition);
        setShowDetail(true);
        setDetailImage(img);;
    };

    const closeDetail = () => {
        setShowDetail(false);
        setDetailImage(false);
        
    }  
    
    return (
        <Layout >
            <Head closeUrl="/sections" hideTitle={false} />
            <main className={`directions-wrap has-sticky ${( sectionIdent & 1 ) ? "odd" : "even" }`}> 

                <Subtitle subTitle={JSONHelper.getSingleTextValue(activeSectionJSON, 'title')} showProgress={true}></Subtitle>

                <div className="directions">
                    <article>
                        <figure>
                            <Image json={imgData} size="medium" />
                        </figure>
                        <h3>Directions</h3>
                        <p>{JSONHelper.getSingleTextValue(directionsJSON, 'description')}</p>
                    </article>

                    <ul className="maps">
                        {directionsJSON.maps.map((json, i) => {
                            return (<Map key={i} no={i + 1} json={json} showDetail={showDetailImage} />)
                        })}
                    </ul>
                    
                </div>

                <div className="bottom">
                    <NavLink to={"/question/" + sectionIdent + "/1"} className="red btn" aria-label="Start">Start</NavLink>
                </div>
 

            </main>


            <div className={`modal-image ${showDetail ? "visible" : ""}`} >
                {(showDetail && detailImage && markerPosition.left) &&
                    <div className="content">
                        <figure className="map-container">
                            <img src={detailImage} alt="" />
                            <span className="marker" style={ {left: markerPosition.left + '%', top: markerPosition.top + '%'} }></span>
                        </figure>
                    </div>
                }
                <a className="close" tabIndex="0" onClick={() => closeDetail()}></a>
            </div>



        </Layout>
    )
}
export default Directions;
