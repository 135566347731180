import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import ProgressBar from '../components/ProgressBar';
import AppContext from '../../AppContext';
import QuizHelper from '../../helpers/Quiz';

const Head = (props) => {

    const appState = useContext(AppContext);
    const [totalProgress, setTotalProgress] = useState(0);
    const [scrollingClass, setScrollingClass] = useState('');

    const scrolling = () => { 
        if (window.scrollY > 0) {
            setScrollingClass('scrolling')
        } else {
            setScrollingClass('')
        }
    }

    const goingBack = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            localStorage.setItem("going_back", "1");
        }
    }

    useEffect(() => {
 
        // compute total
        const totalQuestionsCount = appState.answersTotal;
        var answeredTotalNotEmpty = QuizHelper.getAnsweredQuestionsCount(appState);

        if (answeredTotalNotEmpty && totalQuestionsCount) {
            setTotalProgress(Math.ceil(answeredTotalNotEmpty / totalQuestionsCount * 100));
        } else {
            setTotalProgress(0);
        } 
        
        window.addEventListener('scroll', scrolling); 

        return () => {
            window.removeEventListener('scroll', scrolling);
        }


    }, []);
     
    return (
        <div id="head" className={scrollingClass}>
            <article>
                <h1 className={props.hideTitle ? "invisible" : ""}>Air Cadet Quiz</h1>
            </article>

            <ProgressBar id="progress-overall" total={true} completed={totalProgress} /> 

            {(props.closeUrl) &&
                <NavLink to={props.closeUrl} onClick={(event) => goingBack(event)} onKeyDown={(event) => goingBack(event)} className="close" aria-label="Go Back" tabIndex="0"></NavLink>
            }
        
            {(props.backPageUrl) &&
                <NavLink to={props.backPageUrl} onClick={(event) => goingBack(event)} onKeyDown={(event) => goingBack(event)} className="back" aria-label="Go Back" tabIndex="0"></NavLink>
            }


            {(props.closeCallback) &&
                <a className="close"  onClick={(event) => props.closeCallback(event)} onKeyDown={(event) => props.closeCallback(event)} aria-label="Close" tabIndex="0"></a>
            }

            {(props.closeCallbackAndNavigate) && 
                <NavLink onClick={(event) => {goingBack(event);  props.closeCallbackAndNavigate(event)}} onKeyDown={(event) => {goingBack(event);  props.closeCallbackAndNavigate(event)}} to="/" className="close" aria-label="Go Back" tabIndex="0"></NavLink>
            }

            


        </div>
        )
    };
    export default Head;
