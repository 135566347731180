export default class QuizHelper {


    static getAnsweredQuestionsCount = (appState) => {
        var total = 0;
        if (!appState.answers) {
            return total;
        }

        for (var k in appState.answers) {
            if (appState.answers.hasOwnProperty(k)) {
                total += this.getAnsweredQuestionsCountPerSection(appState, k);
            }
        }

        return total;
    }

    static getAnsweredQuestionsCountPerSection = (appState, sectionIdent) => {
        if (!appState.answers[sectionIdent]) {
            return 0;
        }
        const answeredInSection = appState.answers[sectionIdent];
        var answeredInSectionNotEmpty = 0;
        for (var q in answeredInSection) {
            if (Object.keys(answeredInSection[q]).length) {
                answeredInSectionNotEmpty++;
            }
        }
        return answeredInSectionNotEmpty;
    }

    static getSectionQuestionsCount = (appState, sectionIdent) => {
        if (appState.answersCount[sectionIdent]) {
            return appState.answersCount[sectionIdent];
        } else {
            return 0;
        }
    }


    static getNextSection = (appState, sectionIdent) => {
        let sectionIdents = Object.keys(appState.answersCount);
        for (var s in appState.answersCount) {
            if (!appState.answers.hasOwnProperty(s)) { // not answered 
                return s;
            }
 
            var answeredInSectionNotEmpty = 0;
            for (var q in appState.answers[s]) {
                if (Object.keys(appState.answers[s][q]).length) {
                    answeredInSectionNotEmpty++;
                }
            }

            if(answeredInSectionNotEmpty === 0){ // emtpy answers
                return s;
            }
            
        }

        return false;
    }

    static getSectionScore = (appState, sectionIdent) => {
        if (!appState.answers[sectionIdent]) {
            return 0;
        } 


        const answersCountInCurrentSection = this.getSectionQuestionsCount(appState, sectionIdent)
        const answeredInSection = appState.answers[sectionIdent];
        var correctQuestions = [];
        for (var q in answeredInSection) {
            let question = answeredInSection[q]; 
            if (this.isQuestionCorrect(question)) {
                correctQuestions.push(question);
            }
        } 
        
        return Math.ceil(correctQuestions.length / answersCountInCurrentSection * 100);
    }

    static getTotalScore = (appState) => {
        if (!appState.answers) {
            return 0;
        }

        const answersTotal = appState.answersTotal;
        var correctQuestions = [];

        for (var s in appState.answers) {
            for (var q in appState.answers[s]) {
                let question = appState.answers[s][q];
                if (this.isQuestionCorrect(question)) {
                    correctQuestions.push(question);
                }
            }
        }
        return Math.ceil(correctQuestions.length / answersTotal * 100);
    }



    static isQuestionCorrect(question) {
        let answerIdents = Object.keys(question);
        if (!answerIdents.length) { // no answers
            return false;
        }

        const firstAnswer = question[answerIdents[0]];
        const minimumAnswers = Number(firstAnswer.minimumAnswers);

        if (minimumAnswers !== answerIdents.length) { // wrong number of answers
            return false;
        }


        for (var a in question) { // all needs to be correct
            let answer = question[a];
            if (answer.isCorrect !== true) {
                return false;
            }
        }

        return true;
    }

    static resetQuiz(appState) {
        
        appState.lastTotalProgress = 0; // reset
        appState.lastSectionProgress = 0; // reset

        appState.answers = {};
        localStorage.removeItem("app_state_answers");
    }
}