import React from 'react';

const Layout = (props) => {
  return (
    <div className="page" >   
        { props.children } 
    </div>
  )
}
export default Layout;
