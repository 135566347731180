import React, {useContext, useEffect} from 'react';
import { NavLink } from 'react-router-dom';

import AppContext from '../../AppContext';
import QuizHelper from '../../helpers/Quiz';

const Exiting = (props) => {

    const appState = useContext(AppContext);

    //const [currentProgress, setCurrentProgress] = useState(props.completed);

    const escaping = (event) => { 
        if(event.keyCode === 27) {
            props.resume(event);
        }
    }
    
    useEffect(() => { 

        window.addEventListener('keydown', escaping);  

        return () => {
            window.removeEventListener('keydown', escaping);
        }


    }, []);
    
    const quit = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            localStorage.removeItem("last_page");  
            QuizHelper.resetQuiz(appState);
        }
    }

    return (
        <div id="exiting" className={props.visible ? "visible" : ""} onClick={(event) => props.resume(event)}> 
            <div className="content">
                <article>
                    <p>Are you sure you want to quit the quiz?</p>
                    <p>Finish to see your score.</p>
                </article>

                <div className="bottom two"> 
                    <NavLink tabIndex={(props.visible ? '0' : '-1')} to="/" onClick={(event) => quit(event)} className="red btn na" aria-label="Quit">Quit</NavLink>
                    <NavLink tabIndex={(props.visible ? '0' : '-1')} to="/results" className="red btn na" aria-label="Finish">Finish</NavLink> 
                </div>
            </div>
        </div>
    )
};
export default Exiting;
