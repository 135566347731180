import React, { useState, useContext } from 'react'; 

import AppContext from '../AppContext';
import QuizHelper from '../helpers/Quiz';

import Layout from '../layout/Default';
import Head from './components/Head';
import Subtitle from './components/Subtitle';
import Chart from './components/Chart';
import Share from './components/Share';
import Results from './components/Results';

const Result = (props) => {
    const appState = useContext(AppContext);

    const [showResultsByQuestion, setShowResultsByQuestion] = useState(false);

    const overallResult = QuizHelper.getTotalScore(appState);

    const toggleResultsByQuestion = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            setShowResultsByQuestion(!showResultsByQuestion);
        }
    };

    const close = (event) => { 
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            localStorage.removeItem("last_page");  
            QuizHelper.resetQuiz(appState);
        }
    }

    return (
        <Layout>
            <Head closeCallbackAndNavigate={close} />

            <main className="result has-sticky final">

                <Subtitle subTitle="Quiz complete" finalResult={true} showProgress={true}></Subtitle>

                <article>
                    <h3>You’ve completed all questions. Your score is:</h3>
                </article>

                <Chart pct={overallResult} />

                <ul className="actions">
                    <li>
                        <Share text={true} homepage={false} completed={overallResult} />
                    </li>
                    <li><a tabIndex="0" onClick={(event) => toggleResultsByQuestion(event)}  onKeyDown={(event) => toggleResultsByQuestion(event)} className="safe-link"><span>{showResultsByQuestion ? 'Hide' : 'View'} results by question</span> </a></li>
                </ul>

                <div className={`results-by-question ${(showResultsByQuestion) ? "visible" : "hidden"}`}>
                    <Results isVisible={showResultsByQuestion} />
                </div>

                <ul className="actions with-logos">
                    <li><a href="https://rafm.tours" tabIndex="0"><span>Find other tours and quizes</span></a></li>
                    <li><a href="https://www.rafmuseum.org.uk" className="museum" tabIndex="0"><span>More about the RAF Museum</span></a></li>
                </ul>

            </main>

        </Layout>
    )
}
export default Result;
