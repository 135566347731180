import React, { useState, useContext, useEffect } from 'react';
import ProgressBar from '../components/ProgressBar';
import AppContext from '../../AppContext';
import QuizHelper from '../../helpers/Quiz';

const Subtitle = (props) => {

    const appState = useContext(AppContext);
    const sectionIdent = props.sectionIdent;
    const [currentProgress, setCurrentProgress] = useState(0); 

    useEffect(() => {

        if (props.showProgress) {
            // compute section
            if (sectionIdent) {
                const answersCountInCurrentSection = QuizHelper.getSectionQuestionsCount(appState, sectionIdent);
                var answeredInCurrentSectionNotEmpty = QuizHelper.getAnsweredQuestionsCountPerSection(appState, sectionIdent);

                if (answeredInCurrentSectionNotEmpty && answersCountInCurrentSection) {
                    setCurrentProgress(Math.ceil(answeredInCurrentSectionNotEmpty / answersCountInCurrentSection * 100));
                } else {
                    setCurrentProgress(0);
                }
            }

            if (props.finalResult) {
                setCurrentProgress(100);
            } 
        }

    }, []);



    return ( 
        <div className="subtitle">
            <h2>{props.subTitle}</h2>
            {(props.showProgress && (sectionIdent || props.finalResult)) &&
                <ProgressBar id="progress-section" completed={currentProgress} />
            } 
        </div>  
    )
};
export default Subtitle;
