import React, { useContext } from 'react';

import JSONHelper from '../helpers/JSON';
import AppContext from '../AppContext';

import Layout from '../layout/Default';
import Head from './components/Head';
import Subtitle from './components/Subtitle';
import Section from './components/Section';

const Sections = (props) => {
    const numbers = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const appState = useContext(AppContext)
    const secitonsJSON = JSONHelper.getJSON(appState.data, 'sections');
    const sectionsCount = numbers[secitonsJSON.length];
    const subtitle = `This quiz has ${sectionsCount} location${secitonsJSON.length > 1 ? 's' : ''}. Where would you like to start?`;
 
    return (
        <Layout>
            <Head closeUrl="/" />
            <main className="sections has-sticky"> 

                <Subtitle subTitle={subtitle}></Subtitle>

                <ul className="sections-list">
                    {secitonsJSON.map((json, i) => {
                        return (<Section key={i} no={i+1} json={json} />)
                    })} 
                </ul>

                
            </main>

        </Layout>
    )
}
export default Sections;
