import React from 'react';
import { NavLink } from 'react-router-dom';
import Image from './Image';
import JSONHelper from '../../helpers/JSON';

const Section = (props) => {

    const json = props.json._source;
    const multimedia = JSONHelper.getValue(json.themes[0], 'multimedia', false);
    const key = (props.no < 10 ? '0' + props.no : props.no);
    const questionsCount = json.questions.length;

    var imgData = false;
    var imgUrl = '';
    if (multimedia && multimedia[0]) {
        imgData = multimedia[0];
    }

    if (imgData) {
        imgUrl = JSONHelper.getImageUrl(imgData, "medium");
    }

    return (
        <li>
            <NavLink to={"/directions/" + props.no}>
                <figure className="bgi" style={{ backgroundImage: "url(" + imgUrl + ")" }}>
                    <Image json={imgData} size="medium" />
                </figure>
                <div>
                    <em>{key}</em>
                    <strong>{JSONHelper.getSingleTextValue(json, 'title')}</strong>
                    <span>{questionsCount} question{questionsCount > 1 ? 's' : ''}</span>
                </div>
            </NavLink>
        </li>
    )
};
export default Section;
