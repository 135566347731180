import React, { useContext, useRef, useEffect } from 'react';

import JSONHelper from '../../helpers/JSON';
import AppContext from '../../AppContext';

import Question from './Question';



const ResultsBySection = (props) => {

    const appState = useContext(AppContext);

    const scrollToRef = (ref) => { 
        const yOffset = -80;  
        const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset; 
        window.scrollTo({top: y, behavior: 'smooth'});
    };

    const myRef = useRef(null)

    const sectionIdent = props.no;
    const json = props.json._source;
    const questionsJSON = json.questions;

    

    useEffect(() => {  

        if (props.visibleSection == sectionIdent) {
            setTimeout(() => {
                scrollToRef(myRef);
            }, 350); 
        }
        
    }, [props.visibleSection]);


    return (
        <div ref={myRef} id={`single-result-${sectionIdent}`} className={`single-result ${props.visibleSection == sectionIdent ? "visible" : ""}`}>
            <div className="res-padding"><h2 tabIndex={(props.isVisible ? '0' : '-1')} onClick={(event) => props.toggle(event, sectionIdent)}  onKeyDown={(event) => props.toggle(event, sectionIdent)}>{JSONHelper.getSingleTextValue(json, 'title')}</h2></div>
            <div className="single-result-questions">

                {questionsJSON.map((qjsonRef, i) => {
                    const questionIdent = i + 1;
                    const qectionJSON = appState.data['question_' + sectionIdent + '_' + questionIdent];
                    const questionsTypeText = JSONHelper.getValue(json, 'questionTypeText', 'question'); // question, stop  

                    return (<Question key={i} no={i + 1} isVisible={props.isVisible} visibleSection={props.visibleSection} json={qectionJSON} sectionIdent={sectionIdent} questionIdent={questionIdent} questionsTypeText={questionsTypeText} accordion={true} />)

                })}

            </div>
        </div>
    )
}
export default ResultsBySection;
