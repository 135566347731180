import React from 'react';
import Sponsor from './Sponsor';

const Sponsors = (props) => {

    return props.sponsors ? (
        <ul className="sponsors">
            {props.sponsors.map((sponsor, i) => {
                return (<Sponsor key={i} sponsor={sponsor} />)
            })}
        </ul>
    ) : '';
};
export default Sponsors;
