import React, { useState, useEffect } from 'react'; 
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import config from './config.js';

import { AppProvider } from './AppContext';
import JSONHelper from './helpers/JSON';


import Loading from './pages/Loading';
import ScrollToTop from './pages/components/ScrollToTop';
import Home from './pages/Home';
import Sections from './pages/Sections';
import Directions from './pages/Directions';
import Questions from './pages/Questions';
import Result from './pages/Result';
import Results from './pages/Results';

// jsons - temp imports, later we will fetch data
//import homepageJSON from './data/homepage.json';
//import sectionsJSON from './data/sections.json';

import './App.scss';

function App({ location }) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [context, setContext] = useState({});


    var initalContext = {
        data: {},
        answersCount: {},
        answersTotal: 0,
        answers: {}
    } 

    useEffect(() => {
        
        let ua = window.navigator.userAgent;
        let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        let webkit = !!ua.match(/WebKit/i);
        let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
        if(iOSSafari){
            document.body.classList.add('is-safari');
        }  
        

        //check for saved state
        const localStateAnswers = JSON.parse(localStorage.getItem("app_state_answers"));
        if (localStateAnswers) {
            initalContext.answers = localStateAnswers;
        }

        requestData();
    }, []);

    const requestData = async () => {
        try {
            const responseHomepage = await fetch(config.jsonsUrl + 'homepage.json');
            const responseSections = await fetch(config.jsonsUrl + 'sections.json');
            const homepageJSON = await responseHomepage.json();
            const sectionsJSON = await responseSections.json();

            initalContext.data.homepage = homepageJSON;
            initalContext.data.sections = sectionsJSON;

            // load questions  
            const sections = JSONHelper.getHits(sectionsJSON);
            var answersCount = {};
            var sectionIdent = 0;
            var answersTotal = 0;
            var questionsToLoad = [];

            sections.forEach((s) => {
                sectionIdent++;
                let section = s._source;
                if (section.questions) {
                    section.questions.forEach((q, index) => {
                        questionsToLoad.push({ q: q, sectionIdent: sectionIdent, index: index + 1 });
                    });
                    answersCount[sectionIdent] = section.questions.length;
                    answersTotal += section.questions.length;
                }

            });

            await Promise.all(questionsToLoad.map(async (info) => {
                await requestQuestionData(info.q, info.sectionIdent, info.index);
            }));

            initalContext.answersCount = answersCount;
            initalContext.answersTotal = answersTotal;

            setContext(initalContext);
            setIsLoaded(true); // data loaded 



        } catch (error) {
            console.log(error);
        }
    }
    //requestData(); // importing now 

    const requestQuestionData = async (activeQuestionJSONRef, sectionIdent, questionIdent) => {
        const ident = JSONHelper.getAdminId(activeQuestionJSONRef);
        try {
            const response = await fetch(config.jsonsUrl + ident + '.json');
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const json = await response.json();
            initalContext.data['question_' + sectionIdent + '_' + questionIdent] = JSONHelper.getSource(json);

        } catch (error) {
            console.log(error);
        }

    }

    const isMovingRight = () => {
        let goingBack = localStorage.getItem("going_back") === '1';  
        localStorage.setItem("going_back", '0');
        return goingBack;
    }



    const Contents = withRouter(({ history, location, match }) => {

        if (location.pathname != '/') {
            localStorage.setItem("last_page", location.pathname);
        }


        return (<AppProvider value={context}>
            <ScrollToTop location={location}> 
            <TransitionGroup>
                <CSSTransition
                    key={location.key}
                    timeout={{ enter: 0, exit: 300 }}
                    classNames={'page-slide'}
                >
                    <div
                        className={
                            isMovingRight()
                                ? "slide-right"
                                : "slide-left"
                        }
                    >
                        <Switch location={location} >
                            <Route exact path='/' component={Home} location={location} isLoaded={isLoaded} />
                            <Route exact path='/sections' component={Sections} location={location} />
                            <Route exact path='/directions/:section' component={Directions} location={location} />
                            <Route exact path='/questions/:section' component={Questions} location={location} />
                            <Route exact path='/question/:section/:ident' component={Questions} location={location} />
                            <Route exact path='/result/:section' component={Result} location={location} />
                            <Route exact path='/results' component={Results} location={location} />
                        </Switch>
                    </div>

                </CSSTransition>
            </TransitionGroup> 
            </ScrollToTop>
        </AppProvider>);
    });

    return isLoaded ? (
        <BrowserRouter>
           <Contents />
        </BrowserRouter>
    ) : <Loading />;
}

export default (App);
