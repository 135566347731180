
import config from '../config';

export default class JSONHelper {


    static getSource = (data) => {
        if (data && data.hits) {
            return data.hits.hits[0]._source;
        }
        return false;
    }
    static getHits = (data) => {
        if (data && data.hits) {
            return data.hits.hits;
        }
        return [];
    }
    
    static getJSON = (data, ident) => {
        if (data[ident]) {
            return data[ident].hits.hits;
        }
        return false;
    }

    static getSingleJSON = (data, ident) => {
        if (data[ident]) {
            return this.getContent(data[ident]);
        }
        return false;
    }

    static getContent = (json) => {
        return json.hits.hits[0]._source;
    }

    static getSingleTextValue = (json, property) => {
        if (json[property]) {
            return json[property][0].value;
        }
        return '';
    }

    static getValue = (json, property, ifEmpty) => {
        if (json[property]) {
            return json[property];
        }
        return ifEmpty;
    }

    static getImageUrl = (json, size) => {
        if (size) {  
            if (json.processed && json.processed[size]) { 
                let imgData = json.processed[size];
                if (imgData.location_is_relative) {
                    return config.mediaURL + imgData.location;
                } else {
                    return imgData.location;
                }
            } else {
                return json.source.location;
            }

        } else {
            return json.source.location;
        }
        return '';
    }

    static getSection = (data, ident) => {
        ident--; // starting from 0
        const sections = this.getJSON(data, 'sections');
        if (sections[ident]) {
            return sections[ident]._source;
        }
        return false;
    }

    static getQuestion = (json, ident) => {
        if (json[ident]) {
            return json[ident];
        }
        return false;
    }

    static getAdminId = (json) => {
        if (json.admin && json.admin.id) {
            return json.admin.id;
        }
        return false;
    }
}