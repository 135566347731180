import React, { useContext, useState } from 'react';

import JSONHelper from '../../helpers/JSON';
import AppContext from '../../AppContext'; 

import ResultsBySection from './ResultsBySection';

const Results = (props) => {

    const appState = useContext(AppContext)
    const secitonsJSON = JSONHelper.getJSON(appState.data, 'sections');

    
    const [visible, setVisible] = useState(false);

    const toggleSection = (event, sectionIdent) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            if(sectionIdent == visible){
                sectionIdent = false;
            }
            setVisible(sectionIdent);
        }
    };

    return (
        <main className="results-set">

            {secitonsJSON.map((json, i) => { 
                return (<ResultsBySection key={i} no={i + 1} isVisible={props.isVisible} visibleSection={visible} json={json} toggle={toggleSection} />)
            })} 

        </main>
    )
}
export default Results;
