import React, {useState, useEffect, useContext, useRef} from 'react';

import AppContext from '../../AppContext';

const ProgressBar = (props) => { 
    const appState = useContext(AppContext); 
    const [progress, setProgress] = useState(props.total ? appState.lastTotalProgress : appState.lastSectionProgress);
    
     
    useEffect(() => {  

         
        if(props.completed){ 

            if(props.total){
                appState.lastTotalProgress = progress;
            } else {
                appState.lastSectionProgress = progress;
            }
            
            setTimeout(() => { 
                setProgress(props.completed); 

                if(props.total){
                    appState.lastTotalProgress = props.completed;
                } else {
                    appState.lastSectionProgress = props.completed;
                }

            }, 500);
        }
        
        
    }, [props.completed]);

    return (
        <div id={props.id} className="progress-bar"><div  style={{width: progress + "%"}}></div></div>
    )
};
export default ProgressBar;
