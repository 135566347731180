
const config = {
  mediaURL: 'https://rafm.tours/ciim-media/', 
  mediaKioskURL: '/ciim-internal-media/',
  elasticSearch: 'https://rafm.tours/ciim-search',
  kioskSearch: '/es/ciim-public/_search',
  jsonsUrl: '/json/'
};

module.exports = config;
