import React, { useState, useContext, useEffect } from 'react';
import JSONHelper from '../../helpers/JSON';
import AppContext from '../../AppContext';
import Image from './Image';

const QuestionOption = (props) => {
    const appState = useContext(AppContext)
    const sectionIdent = props.sectionIdent;
    const questionIdent = props.questionIdent;
    const answerIdent = props.no;
    const questionType = props.type;
    const json = props.json;
    const isCorrect = JSONHelper.getValue(json, 'correct', false);
    const isAccorion = props.accordion ? props.accordion : false;

    const [clicked, setClicked] = useState(false);
    const [clickEvent, setClickEvent] = useState(false);

    useEffect(() => {
        if (appState.answers[sectionIdent] && appState.answers[sectionIdent][questionIdent] && appState.answers[sectionIdent][questionIdent][answerIdent]) {
            // was clicked 
            //props.callback(answerIdent, isCorrect, true);
            setClicked(true);
        } else {
            setClicked(false);
        }
    }, [props.state]);

    // @a11yButtonActionHandler
    const selectQuestion = (event) => { 
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            if (!isAccorion) {
                if (!clicked) {
                    props.callback(answerIdent, isCorrect, true);
                } else {
                    props.callback(answerIdent, isCorrect, false);
                }

                setClicked(!clicked);

                setClickEvent(true);

                setTimeout(() => {
                    setClickEvent(false);
                }, 400);

            }
        }



    };

    const renderSwitch = (type) => {
        switch (type) {
            case 'text':
                const text = JSONHelper.getValue(json, 'value', false);
                return (
                    text
                );
            case 'images':
                const multimedia = JSONHelper.getValue(json, 'multimedia', false);
                var imgData = false;
                if (multimedia && multimedia[0]) {
                    imgData = multimedia[0];
                }

                return (
                    imgData ? <Image json={imgData} size="medium" /> : ''
                );
            default:
                return '';
        }
    };

    const answerClass = (type) => {
        var classes = [];
        if (clicked) {
            classes.push('selected');
        }

        if (clickEvent) {
            classes.push('clicked');
        }


        if (isAccorion) {
            if (isCorrect && clicked) {
                classes.push('correct');
            }

            if (isCorrect && !clicked) {
                classes.push('missed');
            }

            if (!isCorrect && clicked) {
                classes.push('wrong');
            }
        }

        return classes.join(' ');
    }

    return (
        <li className={answerClass()} onKeyDown={selectQuestion} onClick={selectQuestion} tabIndex={(props.accordion || props.isExiting ? '-1' : '0')}>
            {renderSwitch(questionType)}
        </li>
    )
};
export default QuestionOption;
