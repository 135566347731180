import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import JSONHelper from '../helpers/JSON';
import AppContext from '../AppContext';
 
import Layout from '../layout/Default';
import Head from './components/Head';
import Exiting from './components/Exiting';
import Subtitle from './components/Subtitle';
import Question from './components/Question';


const Questions = (props) => {
    const appState = useContext(AppContext)

    const sectionIdent = Number(props.match.params.section);
    const questionIdent = Number(props.match.params.ident);
    const nextQuestionIdent = questionIdent + 1;

    const activeSectionJSON = JSONHelper.getSection(appState.data, sectionIdent);
    const questionsJSON = JSONHelper.getValue(activeSectionJSON, 'questions', []);
    const questionsTypeText = JSONHelper.getValue(activeSectionJSON, 'questionTypeText', []); // question, stop 

    const questionsCount = Number(questionsJSON.length);
    const activeQuestionJSONRef = JSONHelper.getQuestion(questionsJSON, questionIdent - 1);
    const lastQuestion = questionIdent === questionsCount;
    const firstQuestion = questionIdent === 1;

    var backPageUrl = "/question/" + sectionIdent + "/" + (questionIdent - 1);
    var nextPageUrl = "/question/" + sectionIdent + "/" + nextQuestionIdent;
    var nextPageButton = questionsTypeText + " " + nextQuestionIdent;
    if (lastQuestion) {
        nextPageButton = "Finish";
        nextPageUrl = "/result/" + sectionIdent;
    }

    if (firstQuestion) {
        backPageUrl = "/sections";
    }
 
    const [questionJSON, setQuestionJSON] = useState(false);
    const [exiting, setExiting] = useState(false);


    useEffect(() => {
        setQuestionJSON(appState.data['question_' + sectionIdent + '_' + questionIdent]);
    }, []);

    const close = (event) => {
        if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            setExiting(true);
        }
    }

    const resume = (event) => {
        //if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
            setExiting(false);
        //}
    }

    if (questionsCount == 0 || !activeQuestionJSONRef) {
        return <Redirect to='/' />;
    }

    return (
        <Layout>

            <Head hideTitle={true} closeCallback={close} backPageUrl={backPageUrl} />

            <main className={`questions  has-sticky ${(sectionIdent & 1) ? "odd" : "even"}`}>

                <Subtitle showProgress={true} subTitle={JSONHelper.getSingleTextValue(activeSectionJSON, 'title')} sectionIdent={sectionIdent}></Subtitle>

                <Question json={questionJSON} isExiting={exiting} sectionIdent={sectionIdent} questionIdent={questionIdent} questionsTypeText={questionsTypeText} accordion={props.accordion} nextPageUrl={nextPageUrl} nextPageButton={nextPageButton} />
 

            </main>

            <Exiting visible={exiting} resume={resume} />
        </Layout>
    );

}
export default Questions;
